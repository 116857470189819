import {withSnackbar} from "react-simple-snackbar";
import React from "react"
import {
    Alert,
    Button,
    Col,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader, Nav, NavItem, NavLink,
    Row, TabContent,
    Table, TabPane
} from "reactstrap";
import PaymentMethodModel from "../../../Models/PaymentMethodModel"
import SingleActivePaymentMethod from "./SingleActivePaymentMethod";
import ConfirmationModalAlert from "../../../Components/ModalAlerts/ConfirmationModalAlert";
import InputWithIcon from "../../../Elements/InputWithIcon";
import {Banks, getBankObject} from "../../../Banks";
import MethodSettings from "./MethodSettings";
import {GetTitle} from "../../../Routes/AdminRoute";
import {ApiErrorThrow} from "../../../Config";
import PaymentMethodSelect from "../../../Elements/PaymentMethodSelect";
import CustomCheckbox from "../../../Elements/CustomCheckbox";
import classnames from "classnames";
import {AiOutlineTable, BiMessageSquareAdd, FiSettings, VscSearchStop} from "react-icons/all";
import StaticPanelTopBar from "../../../Components/StaticPanelTopBar";
import {Skeleton} from "@material-ui/lab";
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import {react_intl} from "../../../index";
import ButtonLoad from "../../../Elements/ButtonLoad";
import AddBankAccountModal from "./AddBankAccountModal";
import {BankAccounts, getBankObjectBankAccount} from "../../../BankAccounts";

const RateInput = (props) => {
    return <input
        onBlur={(e) => {
            e.target.value = (e.target.value).replaceAll(",", ".")
            if (e.target.value === "")
                e.target.value = 0
            if (e.target.value)
                e.target.value = parseFloat(e.target.value).toFixed(2)
            if (typeof props.onChange === "function")
                props.onChange(e)
        }}
        {...props}
    />
}
const ExtraInstallmentInput = (props) => {
    return <input
        onBlur={(e) => {
            e.target.value = (e.target.value).match(/\d+/) !== null ? (e.target.value).match(/\d+/)[0] : ""
            if (e.target.value)
                e.target.value = parseInt(e.target.value)

            if (typeof props.onChange === "function")
                props.onChange(e)
        }}
        {...props}
    />
}

const MethodsSkeleton = () => {
    return <div>
        <Row>
            {[0, 1, 2].map((item, i) => <Col key={`Skeleton-${i}`}>
                <div className={"border p-3 w-100 rounded"}>
                    <Row className={"w-100"}>
                        <Col lg={2} className={"text-center"}>
                            <Skeleton variant={"circle"} width={32} height={32}/>
                        </Col>
                        <Col lg={7}>
                            <Skeleton variant={"text"} width={"100%"} height={32}/>
                        </Col>
                        <Col lg={3}>
                        </Col>
                    </Row>
                    <div className={""}>
                        <Skeleton variant={"text"} width={"100%"} height={25} className={"mb-1"}/>
                        <Skeleton variant={"text"} width={"100%"} height={25}/>
                    </div>
                    <div className={"mt-2 text-center pt-2 border-top"}>
                        <Skeleton variant={"rect"} className={"rounded d-inline-block mr-1 float-none"} width={120}
                                  height={32}/>
                        <Skeleton variant={"rect"} className={"rounded d-inline-block float-none"} width={120}
                                  height={32}/>
                    </div>
                </div>
            </Col>)}
        </Row>
    </div>
}

class InstallmentRow extends React.Component {
    render() {
        let {props} = this
        let {extraInstallmentChanged, rateChanged} = props

        let activated = (props.installmentRate !== null)

        return <tr valign={"middle"}>
            <th><CustomCheckbox
                onChange={e => {
                    if (typeof e.target.checked === "boolean" && !e.target.checked) {
                        rateChanged(null);
                    } else
                        rateChanged("0.00")
                }}
                checked={activated} label={props.title}/></th>
            <td>
                <RateInput
                    className={!activated ? "rate-input disabled" : "rate-input"}
                    disabled={!activated}
                    type={"text"} value={!activated ? "" : props.installmentRate}
                    name={`${props.number}installments`}
                    onChange={(e) => rateChanged(e.target.value)}
                />
            </td>
            <td>
                <ExtraInstallmentInput
                    className={!activated ? "extra-installment-input disabled" : "extra-installment-input"}
                    disabled={!activated}
                    type={"number"} min={1} name={`${props.number}installments_additional`}
                    onChange={(e) => extraInstallmentChanged(e.target.value)}
                />
            </td>
        </tr>
    }
}

class Methods extends React.Component {
    defaultState = {
        activeMethodName: "",
        activeMethodDescription: "",
        activeMethodSettings: [],
        methodEditModal: false,
        activeMethods: [],
        modalInstallment: false,
        settingTab: "settings",
        saving: false
    }
    state = {
        method_type: "vpos",
        activeMethods: [],
        methodSelected: {},
        deleteModalOpen: false,
        changeId: null,
        methodEditModal: false,
        activeMethodSettings: [],
        activeMethodClass: "",
        activeMethodName: "",
        activeMethodDescription: "",
        addMethodModal: false,
        addMethodModalBankAccounts: false,
        createMethodStep: 1,
        createMethodClass: "",
        createMethodClassTemp: "",
        modalInstallment: false,
        activeInstallments: {},
        methodFilter: "",
        methodsLoading: true,
        settingTab: "settings",
        saving: false,
        createLoading: false,
        deleteLoading: false
    }

    settingTabChange = (newTab) => {
        this.setState({
            settingTab: newTab
        })
    }

    methodFilterHandle = (e) => {
        this.setState({
            methodFilter: e.target.value
        })
    }

    constructor(props) {
        super(props);
        this.loadActiveMethods()
        this.modalInstallmentToggle = this.modalInstallmentToggle.bind(this)
        this.setMethodType = this.setMethodType.bind(this)
        this.deleteModalToggle = this.deleteModalToggle.bind(this)
        this.methodEditModalToggle = this.methodEditModalToggle.bind(this)
        this.addMethodModalToggle = this.addMethodModalToggle.bind(this)
        this.addMethodModalBankAccountsToggle = this.addMethodModalBankAccountsToggle.bind(this)
        this.methodHandler = this.methodHandler.bind(this)
        this.methodFilterHandle = this.methodFilterHandle.bind(this)
        this.settingTabChange = this.settingTabChange.bind(this)
    }


    addMethodModalToggle = () => {
        if(this.addVPOSButton !== null
            && typeof this.addVPOSButton === "object"
            && typeof this.addVPOSButton.addMethodModalToggle === "function"
        )
            this.addVPOSButton.addMethodModalToggle();
    }

    addMethodModalBankAccountsToggle = () => {
        if(this.addBankAccountButton !== null
            && typeof this.addBankAccountButton === "object"
            && typeof this.addBankAccountButton.addMethodModalToggle === "function"
        )
            this.addBankAccountButton.addMethodModalToggle();
    }

    methodEditModalToggle = (details) => {
        if (typeof details === "undefined" || details === null)
            details = {}

        let settings = details.settings
        if (typeof settings === "undefined" || settings === null)
            settings = []

        settings = settings.filter(item => (item.name).endsWith("installments"))

        let installments = {}
        settings.map(item => {
            if (item.value !== "" && typeof item.value !== "undefined" && item.value !== null)
                installments[item.name] = parseFloat(item.value).toFixed(2)
            else
                installments[item.name] = null
            return ""
        })

        this.setState({
            methodEditModal: !this.state.methodEditModal
        }, () => {
            let self = this
            setTimeout(function () {
                self.setState({
                    methodSelected: details,
                    activeMethodSettings: details.settings || [],
                    activeMethodClass: details.class,
                    activeMethodName: details.name,
                    activeMethodDescription: details.description,
                    activeInstallments: installments
                })
            }, this.state.methodEditModal ? 0 : 300)
        })
    }

    setMethodType = (typeStr, callback) => {
        this.setState({
            method_type: typeStr
        }, () => {
            if (typeof callback === "function") callback()
        })
    }

    modalInstallmentToggle = (details) => {
        let settings = details.settings
        if (typeof settings === "undefined" || settings === null)
            settings = []

        settings = settings.filter(item => (item.name).endsWith("installments"))

        let installments = {}
        settings.map(item => {
            if (item.value !== "" && typeof item.value !== "undefined" && item.value !== null)
                installments[item.name] = parseFloat(item.value).toFixed(2)
            else
                installments[item.name] = null
            return item;
        })

        this.setState({
            modalInstallment: !this.state.modalInstallment
        }, () => {
            let self = this
            setTimeout(function () {
                self.setState({
                    methodSelected: details,
                    activeMethodSettings: details.settings || [],
                    activeMethodClass: details.class,
                    activeMethodName: details.name,
                    activeMethodDescription: details.description,
                    activeInstallments: installments
                })
            }, this.state.modalInstallment ? 0 : 300)
        })
    }

    deleteModalToggle = (details) => {
        this.setState({
            methodSelected: details || {},
            deleteModalOpen: !this.state.deleteModalOpen
        })
    }

    loadActiveMethods = () => {
        this.setState({activeMethods: [], methodsLoading: true})
        PaymentMethodModel.getActiveMethods(['settings'], this.state.method_type).then(r => r.json())
            .then(r => {
                let data = r.data
                let {settings} = r.included
                data.forEach((item, key) => {
                    if (typeof settings[item.id] === "object") {
                        data[key]['attributes']['settings'] = settings[item.id]
                    }
                })
                this.setState({
                    activeMethods: data,
                    methodsLoading: false,
                    deleteLoading: false
                })
            })
    }

    methodHandler = (e) => {
        let state = {}
        state[e.target.name] = e.target.value
        this.setState(state)
    }

    componentDidMount() {
        document.title = GetTitle(react_intl.formatMessage({id: "admin.payment.methods.title"}))
    }

    addVPOSButton = null
    addBankAccountButton = null

    render() {
        let bankDetails = {}, bankClass
        let {openSnackbar} = this.props
        let {
            activeMethods,
            methodSelected,
            activeMethodSettings,
            activeMethodClass,
            activeInstallments,
            methodsLoading
        } = this.state

        if (Object.keys(methodSelected).length)
            bankClass = methodSelected.class

        if (this.state.createMethodStep === 2 && this.state.createMethodClass)
            bankClass = this.state.createMethodClass

        else if (typeof activeMethodClass === "string")
            bankClass = activeMethodClass

        let activeMethodIsBankAccount = (typeof methodSelected.method_type === "string" && methodSelected.method_type === "bankaccount");

        if (bankClass) {
            if (activeMethodIsBankAccount)
                bankDetails = getBankObjectBankAccount(bankClass)
            else
                bankDetails = getBankObject(bankClass)
        }

        let activeBankSettings = typeof bankDetails.settings === "object" && bankDetails.settings !== null ? bankDetails.settings : {}

        if (!activeMethodIsBankAccount)
            activeBankSettings['convert_currency_on_transaction'] = {
                title: react_intl.formatMessage({id: "admin.payment.methods.covert_on_payment"}),
                description: react_intl.formatMessage({id: "admin.payment.methods.covert_on_payment_desc"}),
                type: "currency",
                value: "",
                default: ""
            }

        let activeBankInstallments = typeof bankDetails.installments === "object" && bankDetails.installments !== null ? bankDetails.installments : []

        return <div>
            <StaticPanelTopBar
                title={<FormattedMessage id={"admin.payment.methods.active_methods"}/>}
                extra={<div className={"text-right padding-top-25"}>
                    <Link
                        to={'/admin/payment-rules'}
                        className={"btn btn-secondary mr-2"}
                        onClick={this.addMethodModalToggle}><FormattedMessage id={"admin.payment.methods.edit_rules"}/>
                        {" "}
                        <i
                            className={"icon-feather-edit"}/>
                    </Link>

                    <AddBankAccountModal
                        {...this.props}
                        modalTitle={<FormattedMessage id={"admin.payment.methods.add_modal_title"}/>}
                        buttonText={<><FormattedMessage id={"admin.payment.methods.add_new"}/> <i className={"icon-feather-plus"}/></>}
                        setMethodType={this.setMethodType}
                        methodType={"vpos"}
                        accountList={Banks}
                        buttonColor={"primary"}
                        accountGet={getBankObject}
                        loadActiveMethods={this.loadActiveMethods}
                        callback={(item) => {
                            this.addVPOSButton = item;
                        }}
                    />

                    <div className={"mx-1 d-inline"}>
                        <AddBankAccountModal
                            {...this.props}
                            modalTitle={<FormattedMessage id={"admin.payment.methods.add_bank_account_modal_title"}/>}
                            buttonText={<><FormattedMessage id={"admin.payment.methods.add_new_bank_account"}/> <i className={"icon-feather-plus"}/></>}
                            setMethodType={this.setMethodType}
                            methodType={"bankaccount"}
                            accountList={BankAccounts}
                            accountGet={getBankObjectBankAccount}
                            loadActiveMethods={this.loadActiveMethods}
                            callback={(item) => {
                                this.addBankAccountButton = item;
                            }}
                        />
                    </div>
                </div>}
            />

            <div className="margin-top-100 margin-bottom-20 bg-white p-2 border rounded">
                <Nav pills>
                    {[
                        {
                            label: "Sanal POS",
                            value: "vpos"
                        },
                        {
                            label: "Havale/EFT",
                            value: "bankaccount"
                        }
                    ].map(item => <NavItem>
                        <NavLink
                            className={classnames("cursor-pointer user-select-none", {"active bg-paymendo text-white": this.state.method_type === item.value})}
                            onClick={() => {
                                this.setMethodType(item.value, this.loadActiveMethods)
                            }}
                        >{item.label}</NavLink>
                    </NavItem>)}
                </Nav>
            </div>

            <div className="dashboard-box p-3 mt-0 margin-bottom-10">



                <div className={"text-center w-100"}>
                    {methodsLoading && <MethodsSkeleton/>}
                    {!methodsLoading && <Row className={"pt-0"}>
                        {activeMethods.length ? activeMethods.map(item => <SingleActivePaymentMethod
                            reload={this.loadActiveMethods}
                            details={item}
                            editToggle={this.methodEditModalToggle}
                            editInstallmentToggle={this.modalInstallmentToggle}
                            deleteToggle={this.deleteModalToggle}
                        />) : <>
                            <Col sm={12} className={"d-block"}>
                                <h3><VscSearchStop size={48}/></h3>
                            </Col>
                            <Col sm={12} className="d-block">
                                <FormattedMessage id={"admin.payment.methods.no_active"} tagName={"h3"}/>
                            </Col>
                            <Col sm={12} className="d-block"><FormattedMessage id={"admin.payment.methods.no_active_desc"} tagName={"p"}/></Col>
                            <Col sm={12} className="d-block">
                                <Button onClick={
                                    this.state.method_type !== "bankaccount" ? this.addMethodModalToggle : this.addMethodModalBankAccountsToggle
                                }><FormattedMessage
                                    id={"admin.payment.methods.start_first"}/> <BiMessageSquareAdd/></Button>
                            </Col>
                        </>}
                    </Row>}
                </div>

                <ConfirmationModalAlert
                    title={react_intl.formatMessage({id: "admin.payment.methods.delete_title"})}
                    isOpen={this.state.deleteModalOpen}
                    buttonLoading={this.state.deleteLoading}
                    toggle={this.deleteModalToggle}
                    icon={"icon-feather-alert-circle"}
                    buttonAction={() => {
                        if (!this.state.changeId) {
                            openSnackbar(react_intl.formatMessage({id: "admin.payment.methods.select_alternative"}));
                            return false;
                        }
                        this.setState({
                            deleteLoading: true
                        })
                        PaymentMethodModel.delete(this.state.methodSelected.id, this.state.changeId).then(() => {
                            openSnackbar(react_intl.formatMessage({id: "admin.payment.methods.deleted"}))
                            this.loadActiveMethods()
                            this.deleteModalToggle()
                        }).catch(r => {
                            this.setState({
                                deleteLoading: false
                            })
                            ApiErrorThrow(r, openSnackbar)
                        })
                    }}
                >
                    <Alert><i
                        className={"icon-line-awesome-exclamation-triangle"}/>
                        <FormattedMessage id={"admin.payment.methods.alert_message"} values={
                            {
                                name: this.state.methodSelected.name
                            }
                        }/></Alert>
                    <p><FormattedMessage id={"admin.payment.methods.alert_message_desc"}/></p>
                    <div className={"text-left"}>
                        <PaymentMethodSelect
                            placeholder={react_intl.formatMessage({id: "admin.payment.methods.alternative_placeholder"})}
                            isMulti={false}
                            onChange={(e) => {
                                this.setState({changeId: e.value})
                            }}
                        />
                    </div>
                </ConfirmationModalAlert>

                <Modal isOpen={this.state.methodEditModal} toggle={this.methodEditModalToggle} size={"lg"}
                       returnFocusAfterClose={false}
                       onClosed={() => {
                           this.setState({settingTab: 'settings'})
                       }
                       }>
                    <ModalHeader
                        toggle={this.methodEditModalToggle}>{react_intl.formatMessage({id: "admin.payment.methods.edit_modal_title"}, {name: this.state.methodSelected.name})}
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col lg={3}>
                                <Nav pills={true} vertical={true}>
                                    <NavItem onClick={() => this.settingTabChange('settings')}>
                                        <NavLink href={"#"}
                                                 active={this.state.settingTab === "settings"}><FiSettings/>
                                            {" "}<FormattedMessage
                                                id={"admin.payment.methods.edit_modal.settings"}/></NavLink>
                                    </NavItem>
                                    {(
                                        typeof this.state.methodSelected.method_type !== "string"
                                        ||
                                        this.state.methodSelected.method_type !== "bankaccount"
                                    ) && <NavItem
                                        className={classnames({
                                            "d-none": this.state.activeMethodClass === null || this.state.activeMethodClass === "" ||
                                                (getBankObject(this.state.activeMethodClass) || {}).installments === null
                                        })}
                                        onClick={() => this.settingTabChange('installment')}>
                                        <NavLink href={"#"}
                                                 active={this.state.settingTab === "installment"}> <AiOutlineTable/>
                                            {" "}<FormattedMessage
                                                id={"admin.payment.methods.edit_modal.installment"}/></NavLink>
                                    </NavItem>}
                                </Nav>
                            </Col>
                            <Col lg={9}>
                                <TabContent activeTab={this.state.settingTab}>
                                    <TabPane tabId={"settings"}>
                                        <Row>
                                            <Col>
                                                <Label className={"w-100"}>
                                                    <FormattedMessage id={"admin.payment.methods.edit_modal.name"}/>
                                                    <InputWithIcon type={"text"}
                                                                   className={"w-100"}
                                                                   name={"activeMethodName"}
                                                                   value={this.state.activeMethodName}
                                                                   onChange={this.methodHandler}
                                                    />
                                                </Label>
                                            </Col>
                                            <Col>
                                                <Label className={"w-100"}>
                                                    <FormattedMessage id={"admin.payment.methods.edit_modal.desc"}/>
                                                    <InputWithIcon type={"text"} name={"activeMethodDescription"}
                                                                   value={this.state.activeMethodDescription}
                                                                   onChange={this.methodHandler}
                                                    />
                                                </Label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12}>
                                                <Label><i className={"icon-line-awesome-cogs"}/> <FormattedMessage
                                                    id={"admin.payment.methods.edit_modal.settings"}/></Label>
                                            </Col>
                                            <Table className={""}>
                                                <tbody>
                                                {activeBankSettings ? Object.keys(activeBankSettings).map((key, counter) => {
                                                    let item = activeBankSettings[key]
                                                    return <MethodSettings
                                                        z_index_value={999 - counter}
                                                        settingKey={key}
                                                        id={methodSelected.id}
                                                        value={(activeMethodSettings.find(item => item.name === key) || {}).value}
                                                        details={item} handler={this.methodSettingHandler}/>
                                                }) : ""}
                                                </tbody>
                                            </Table>
                                        </Row>
                                    </TabPane>
                                    {(
                                        typeof this.state.methodSelected.method_type !== "string"
                                        ||
                                        this.state.methodSelected.method_type !== "bankaccount"
                                    ) && <TabPane tabId={"installment"}>
                                        <table className={"table table-striped w-100 installment-table text-center"}
                                               cellSpacing={5} cellPadding={5} border={0}>
                                            <thead>
                                            <tr valign={"middle"}>
                                                <th width={"30%"} align={"right"}><FormattedMessage
                                                    id={"admin.payment.methods.edit_modal.installment_count"}/></th>
                                                <th width={"40%"}><FormattedMessage
                                                    id={"admin.payment.methods.edit_modal.installment_rate"}/></th>
                                                <th width={"30%"}><FormattedMessage
                                                    id={"admin.payment.methods.edit_modal.installment_extra"}/></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {activeBankInstallments ? Object.keys(activeBankInstallments).map((key, i) => {
                                                let item = activeBankInstallments[key]
                                                let count = i + 2
                                                return <InstallmentRow
                                                    rateChanged={(val) => {
                                                        let installments_copy = {...activeInstallments}
                                                        installments_copy[`${count}installments`] = val;
                                                        this.setState({
                                                            activeInstallments: installments_copy
                                                        })
                                                    }}
                                                    extraInstallmentChanged={(val) => {
                                                        let installments_copy = {...activeInstallments}
                                                        installments_copy[`${count}installments_extra`] = val;
                                                        this.setState({
                                                            activeInstallments: installments_copy
                                                        })
                                                    }}
                                                    title={item.title}
                                                    number={count}
                                                    installmentRate={typeof activeInstallments[`${count}installments`] !== "undefined" && activeInstallments[`${count}installments`] !== "" ? activeInstallments[`${count}installments`] : ""}
                                                    extraInstallment={typeof activeInstallments[`${count}installments_extra`] !== "undefined" && activeInstallments[`${count}installments_extra`] !== "" ? activeInstallments[`${count}installments_extra`] : ""}
                                                />
                                            }) : ""}
                                            </tbody>
                                        </table>
                                    </TabPane>}
                                </TabContent>
                            </Col>
                        </Row>
                    </ModalBody>

                    <ModalFooter>
                        <ButtonLoad
                            style={{zIndex: 0}}
                            onClick={() => this.save()}
                            color={"primary"}
                            loading={this.state.saving}
                            text={react_intl.formatMessage({id: "admin.payment.methods.edit_modal.save"})}
                        />
                    </ModalFooter>
                </Modal>
            </div>
        </div>;
    }


    methodSettingHandler = (e) => {
        if (e

                .target
                .type
            ===
            "checkbox"
        )
            e
                .target
                .value = e.target.checked ? "on" : "off"
        let
            state = this.state.activeMethodSettings
        let
            _name = e.target.name
        let
            _value = e.target.value
        let
            exists = false
        state = state.map(item => {
            if (item.name === _name) {
                item.value = _value
                exists = true
            }
            return item
        })

        if (

            !
                exists
        )
            state
                .push({
                    name: _name,
                    value: _value
                })

        this
            .setState({
                activeMethodSettings: state
            })
    }

    findWithAttr(array, attr, value) {
        for (let i = 0; i < array.length; i += 1) {
            if (array[i][attr] === value) {
                return i;
            }
        }
        return -1;
    }

    save = () => {
        let {openSnackbar} = this.props
        let {activeInstallments, activeMethodSettings} = this.state
        this.setState({saving: true})
        let settings = Object.assign([], activeMethodSettings)
        if (typeof activeInstallments === "object") {
            Object.keys(activeInstallments).forEach(item => {
                let indexOfSetting = this.findWithAttr(settings, 'name', item)
                if (indexOfSetting === -1)
                    settings.push({
                        name: item,
                        value: activeInstallments[item]
                    })
                else
                    settings[indexOfSetting].value = activeInstallments[item]
            })
        }

        let details = {
            "name": this.state.activeMethodName,
            "description": this.state.activeMethodDescription,
            "settings": settings
        }
        PaymentMethodModel.saveSettings(this.state.methodSelected.id, details)
            .then(() => {
                this.setState(this.defaultState, this.loadActiveMethods)
            }).catch(r => ApiErrorThrow(r, openSnackbar))
    }

    // saveInstallments = () => {
    //     let {openSnackbar} = this.props
    //     let {activeInstallments, methodSelected} = this.state
    //     PaymentMethodModel.saveSettings(methodSelected.id, {
    //         settings: activeInstallments
    //     }).then(r => {
    //         this.setState(this.defaultState, this.loadActiveMethods)
    //     }).catch(r => ApiErrorThrow(r, openSnackbar))
    // }

}

export default withSnackbar(Methods)
